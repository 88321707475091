import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';

import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { KnowledgeBaseArticleLinkModule } from '@app/pipes/knowledge-base-article-link.module';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationBannerMessageModel } from '@app/+authenticated/shared/notification-banner/notification-banner.component.model';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { RouterModule } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
  selector: 'notification-banner',
  templateUrl: './notification-banner.component.html',
  imports: [
    FeatureFlagPipe,
    TranslationParamsPipe,
    KnowledgeBaseArticleLinkModule,
    TranslateModule,
    IconComponent,
    RouterModule,
    NgIf,
  ],
  providers: [DialogService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBannerComponent {
  public dataSubs = new Subscription();

  @Output()
  public closeHandler = new EventEmitter();

  @Input()
  public showSupportButton: boolean;
  @Input()
  public showFeatureBanner: boolean = false;
  @Input()
  public showCloseIcon: boolean = true;
  @Input()
  public notificationBannerMessage: NotificationBannerMessageModel;
}
