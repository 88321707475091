<div class="relative flex rounded-[3px] border-[1px] border-solid border-grey-200 bg-white p-[10px]">
  <div *ngIf="showCloseIcon" class="absolute right-[10px] top-[0px]">
    <a class="close-icon" (click)="closeHandler.emit()">
      <icon class="si modal-close__icon h-[10px] w-[10px]" svgIcon="close"></icon>
    </a>
  </div>
  <div
    class="flex h-[30px] w-[30px] items-center justify-center rounded-[60px] border-[1px] border-solid border-grey-200 bg-white p-1 shadow-lg"
  >
    {{ notificationBannerMessage.emoji }}
  </div>
  <div class="flex-column ml-[10px] flex">
    <span class="font-semibold text-word-mark-800">{{ notificationBannerMessage.title | translate }}</span>
    <span [innerHTML]="notificationBannerMessage.innerHTML | translate"></span>
    <div class="mt-[10px] flex flex-row gap-2">
      <a
        *ngIf="showSupportButton"
        [routerLink]="['', { outlets: { modal: ['support'] } }]"
        class="btn p-button-md p-button-outlined p-button-primary flex h-full items-center self-center border-none bg-grey-200 px-4 font-medium text-grey-500 hover:bg-grey-300"
      >
        {{ 'Support' | translate }}</a
      >
      <a
        *ngIf="notificationBannerMessage?.externalUrl"
        href="{{ notificationBannerMessage?.externalUrl | knowledgeBaseArticleLink }}"
        target="_blank"
        class="btn p-button-md p-button-outlined p-button-primary flex h-full items-center self-center border-none bg-grey-200 px-4 font-medium text-grey-500 hover:bg-grey-300"
      >
        {{ 'Learn more' | translate }}</a
      >
      <a
        *ngIf="notificationBannerMessage?.redirectLink"
        [routerLink]="notificationBannerMessage?.redirectLink"
        class="btn p-button-md p-button-outlined p-button-primary flex h-full items-center self-center border-none bg-purple-100 px-4 font-medium text-purple-500 hover:bg-purple-200"
      >
        {{ 'View changes' | translate }}
        <icon class="ml-[4px] h-[10px]" svgIcon="arrow-right"></icon>
      </a>
    </div>
  </div>
</div>
