import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface InvoiceModel {
  id: string;
  access_code: string | null;
  account_id: string;
  amount_paid: string | null;
  created: string | null;
  due_date: string;
  invoice_date: string;
  invoice_number: string | null;
  payment_date: string | null;
  payment_method: string | null;
  payment_url: string | null;
  period_end: string | null;
  period_start: string | null;
  status: 'draft' | 'open' | 'scheduled' | 'pending_payment' | 'late' | 'reminded' | 'paid' | 'uncollectible' | null;
  total: string | null;
  total_excl_tax: string | null;
  updated: string | null;
  source: InvoiceSource;
  currency_code?: string;
}

export enum InvoiceSource {
  MONEYBIRD = 'moneybird',
  CHARGEBEE = 'chargebee',
}

export const notificationBannerMessage = {
  title: _('We’re hard at work!'),
  emoji: '🔧',
  innerHTML: _('Right now we are working on a data migration, please check back tomorrow.'),
};
